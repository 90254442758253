import $ from 'jquery';
window.jQuery = $;
window.$ = $;

//Components
import '../scss/theme.scss';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import Cookies from 'js-cookie'

//Images
import '../image/logo.svg';

//MMENU
import 'mmenu-js';
import 'mhead-js';

//Check for cookie
$(window).on('load',function(){
	//Load Announcementbar
	if(Cookies.get('co_topbar')) {
	} else {
		jQuery('#topBarCollapse').collapse('show');
	}
});

jQuery(document).ready(function (jQuery) {
	//SVG INJECTOR
	var elementsToInject = document.querySelectorAll('.inject-me');
	var injectorOptions = {
		evalScripts: 'once'
	};
	var injector = new SVGInjector(injectorOptions);
	if (jQuery(elementsToInject).length) {
		injector.inject(
			elementsToInject
		);
	}

	//ANNOUNCEMENT BAR
	jQuery('#topBarCollapse').on('hidden.bs.collapse', function(e) {
		Cookies.set('co_topbar', 'set', { expires: 1 });
	});

});

document.addEventListener(
	"DOMContentLoaded", () => {
		const menu = new Mmenu( "#site-header-nav", {
			wrappers: ["bootstrap"],
		}, {
			offCanvas: {
				clone: true,
				page: {
					selector: "#body-wrap"
				}
			},
		} );
		new Mhead( ".site-header", {
			hide: 200
		});
		const api = menu.API;
		document.querySelector( ".mburger" )
			.addEventListener(
				"click", ( evnt ) => {
					evnt.preventDefault();
					api.open();
				}
			);
        }
);


//Exists Function
jQuery.fn.exists = function (callback) {
	var args = [].slice.call(arguments, 1);

	if (this.length) {
		callback.call(this, args);
	}

	return this;
};